import React, { useState, useEffect } from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Description } from "../../types";
import BalanceGate from "./BalanceGate/BalanceGate";
import ManifestationGate from "./ManifestationGate/ManifesationGate";
import { BALANCE_GATE_ROUTE, MANIFESTATION_GATE_ROUTE } from "src/routes";
import Link from "src/components/Link/Link";
import { get, titleSize, descSize } from "src/api";
import { FormattedMessage } from "react-intl";
import useLocale from "src/providers/useLocale";
import BaseView from "./BaseView";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
const Gates = () => {
  let locale = useLocale()[0];
  const [content, setContent] = useState("Loading ...");
  useEffect(() => {
    get<Description>("/description/gates").then((obj) => {
      setContent(obj?.content || "");
    });
  }, [locale]);

  return (
    <>
      <BaseView noBread />

      <VStack alignItems="start">
        <Box>
          <SectionHeader slug="gate" />
        </Box>
        <Box>
          <Text color="#8b8b8b" fontSize={descSize}>
            {content}
          </Text>
        </Box>
        <VStack w="100%" marginTop="30px !important">
          <HStack flexWrap="wrap" alignItems="center">
            <Link
              to={BALANCE_GATE_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="balance Portal" />
            </Link>
            <Link
              to={MANIFESTATION_GATE_ROUTE}
              borderRadius={15}
              py={1}
              isSecondary
              margin={["5px 2px !important", "5px !important"]}
            >
              <FormattedMessage id="transfigurationgate" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

Gates.BalanceGate = BalanceGate;
Gates.ManifestationGate = ManifestationGate;

export default Gates;
