import { createSlice } from "@reduxjs/toolkit";

interface TutorialsState {
  tutorialsEnabled: boolean;
}

const tutorialsSlice = createSlice({
  name: "tutorials",
  initialState: {
    tutorialsEnabled: false,
  } as TutorialsState,
  reducers: {
    enableTutorials: (state) => {
      state.tutorialsEnabled = true;
    },
    disableTutorials: (state) => {
      state.tutorialsEnabled = false;
    },
  },
});

export const { enableTutorials, disableTutorials } = tutorialsSlice.actions;
export default tutorialsSlice.reducer;
