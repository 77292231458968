import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Text,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Spinner,
  AbsoluteCenter,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { PureToneData } from "src/types";
import { getPureTones } from "src/api/sal-magic/query";
import { useQuery } from "react-query";
import { PURE_TONES_ROUTE, FREQUENCY_ROUTE, WAVES_ROUTE } from "src/routes";
import { FormattedMessage } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import SalMagicGate from "./components/SalMagicGate";
import SalMagicAudioPlayer from "./components/SalMagicPlayer/SalMagicAudioPlayer";
import SectionHeader from "src/components/SectionHeader/SectionHeader";

const PureTones = React.memo(() => {
  const {
    data: pureTonesData,
    isLoading: isPureTonesLoading,
    error,
    refetch: refetchPureTones,
  } = useQuery<PureToneData, Error>(
    ["listPureTones", localStorage.getItem("locale")],
    getPureTones,
    {
      enabled: true,
      retry: 3,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
    }
  );

  // State to hold durations keyed by track URL or track ID
  const [durations, setDurations] = useState<Record<string, number>>({});

  useEffect(() => {
    if (pureTonesData) {
      // Extract all track URLs from the data
      const allTracks = pureTonesData.flatMap(
        (category) => category.pure_tones
      );

      // Create a copy of durations to not mutate state directly
      const newDurations = { ...durations };

      // A helper function to load the duration for a single track
      const loadDuration = (trackUrl: string, trackId: number) => {
        return new Promise<void>((resolve) => {
          const audio = new Audio();
          audio.preload = "metadata";
          audio.src = trackUrl;
          audio.addEventListener("loadedmetadata", () => {
            newDurations[trackId] = audio.duration;
            resolve();
          });
          audio.addEventListener("error", () => {
            // If there's an error, set duration to 0 or handle accordingly
            newDurations[trackId] = 0;
            resolve();
          });
        });
      };

      // Load all durations and once done, update state
      Promise.all(allTracks.map((t) => loadDuration(t.url, t.id))).then(() => {
        setDurations(newDurations);
      });
    }
  }, [pureTonesData]);

  if (isPureTonesLoading) {
    return (
      <AbsoluteCenter>
        <Spinner aria-label="loading..." size="xl" />
      </AbsoluteCenter>
    );
  }

  if (error) {
    return (
      <Box my={10}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error loading data</AlertTitle>
          <AlertDescription>
            <FormattedMessage
              id="dataLoadError"
              defaultMessage="Unable to load the data. Please try again later."
            />
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      </Box>
    );
  }

  return (
    <Box my={10}>
      <ChakraBreadcrumb
        color="gray.900"
        textTransform="capitalize"
        separator=" "
        mb={10}
      >
        <BreadcrumbItem w="100%">
          <>
            <BreadcrumbLink as={ReactRouterLink} to={FREQUENCY_ROUTE}>
              <FormattedMessage id="frequency" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={WAVES_ROUTE}>
              <FormattedMessage id="waves" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={PURE_TONES_ROUTE}>
              <FormattedMessage id="Pure Tones" />
            </BreadcrumbLink>
          </>
        </BreadcrumbItem>

        <BreadcrumbItem
          isCurrentPage
          w="100%"
          justifyContent="space-between"
          alignItems="start"
        >
          <SectionHeader slug="pure_tones" />
          <SalMagicGate radius={105} />
        </BreadcrumbItem>
      </ChakraBreadcrumb>
      <VStack
        display={"flex"}
        wrap={"wrap"}
        margin={"auto"}
        width="100%"
        flexDir="row"
        justifyContent="center"
        alignItems="center"
      >
        <Accordion key="pure_tone_cats" w="100%" allowMultiple>
          {pureTonesData
            ?.sort((a, b) => a.id - b.id)
            .map((category) => (
              <span id={`category-${category.id}`}>
                <AccordionItem
                  key={`item-${category.id}`}
                  id={`category-${category.id}`}
                >
                  <h2>
                    <AccordionButton key={`button-${category.id}`}>
                      <Text flex="1" textAlign="left" fontWeight="bold">
                        {category.name}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={{ base: 5, md: 10 }}>
                    <VStack width="100%" spacing={6}>
                      {category.pure_tones
                        .sort((a, b) => a.id - b.id)
                        .map((pure_tone, index) => (
                          <VStack
                            key={`${index}:vstack-pure-tone${pure_tone.id}`}
                            width="100%"
                          >
                            <SalMagicAudioPlayer
                              key={`${index}:pure-tone-${pure_tone.id}`}
                              entityName={"pure_tones"}
                              trackUrl={pure_tone.url}
                              trackName={pure_tone.name}
                              trackCategory={category.name}
                              trackInfo={pure_tone.description}
                              trackId={pure_tone.id}
                              trackCover={pure_tone.imageUrl}
                              trackRating={pure_tone.rating}
                              currentUserRating={pure_tone.myRating}
                              refetchTracks={refetchPureTones}
                              categoryId={category.id.toString()}
                              // Pass the preloaded duration here if available
                              preloadedDuration={durations[pure_tone.id] ?? 0}
                            />
                            {index < category.pure_tones.length - 1 && (
                              <hr
                                style={{
                                  // margin: "36px",
                                  height: "2px",
                                  width: "80%",
                                }}
                              />
                            )}
                          </VStack>
                        ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </span>
            ))}
        </Accordion>
      </VStack>
    </Box>
  );
});

export default PureTones;
