import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Text,
  Tooltip,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppContext } from "src/Providers";
import { useDispatch, useSelector } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import { put, get } from "src/api";
import { FaVideo } from "react-icons/fa6";

const clearNotification = async (sectionName) => {
  return await put(`/shared/notification/${sectionName}`, { seen: true });
};

const Category = ({ category, notifications }) => {
  const { locale, formatMessage } = useIntl();
  const context = useAppContext();
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  const doneCalibrating = useSelector((state) => state.calibration.done);
  const location = useLocation();
  const condition =
    (!doneCalibrating && category.name === "sendingintention") ||
    (!doneCalibrating && category.name === "subliminal");
  const isActive = location.pathname.includes(category.route);

  const { tutorialsEnabled } = useSelector((state) => state.tutorials);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [slug, setSlug] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");

  useEffect(() => {
    get(`/video-tutorial/${slug}`, locale).then((res: any) => {
      setVideoUrl(res.url || "");
    });
  }, [slug, locale]);

  return (
    <li>
      <Link
        onClick={() => {
          clearNotification(category.name).finally(() => {
            if (condition) {
              calibrationFunc();
            }
          });
        }}
        transition="none"
        as={condition || category.upcoming ? "button" : ReactRouterLink}
        width={condition || category.upcoming ? "100%" : "auto"}
        to={condition || category.upcoming ? `#` : category.route}
        _activeLink={{ color: "white" }}
        _focus={{ outline: "none", border: "none" }}
        _hover={{ textDecoration: "none" }}
        aria-current={isActive ? "page" : "false"}
      >
        <Box
          style={{
            position: "relative",
            overflow: "visible", // Set overflow to "visible" for notifications
          }}
        >
          <Text
            as={"div"}
            d="flex"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            px={3}
            py={2.5}
            fontWeight="normal"
            style={{
              color: "black",
              // padding: "0.5em",
              backgroundColor: category.upcoming ? "silver" : "#f9f9f9",
              borderRadius: "8px",
              boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
              marginTop: "0.8em",
              marginBottom: "0em",
              fontFamily: "Calibri (Body)",
              fontSize: "18px",
              letterSpacing: "normal",
              fontStyle: "normal",
              position: "relative",
              overflow: "hidden", // Set overflow to "hidden" for the new banner
              cursor: category.upcoming ? "default" : "pointer",
            }}
            onClick={() => !condition && context.background?.set("#fff")}
          >
            <Text flex={1} textAlign="center">
              {formatMessage({
                id: category.name,
              })}
            </Text>
            {tutorialsEnabled && (
              <Box d="flex" justifyContent="center" alignItems="center">
                <Icon
                  as={FaVideo}
                  color="#1f3f93"
                  aria-label="Play"
                  fontSize="1.3em"
                  backgroundColor="transparent"
                  onClick={(e) => {
                    e.preventDefault();
                    setSlug(category.name);
                    onOpen();
                  }}
                />
              </Box>
            )}
            {/* Content of the new banner */}
            {category.upcoming && (
              <Box
                position="absolute"
                left="-30px"
                top="2px"
                bg="brand.800"
                color="white"
                padding="5px"
                width="100px"
                transform={`rotate(${locale === "ar" ? "" : "-"}45deg)`}
              >
                <Text as={"div"}>
                  {formatMessage({
                    id: "soon",
                  })}
                </Text>
              </Box>
            )}
            {category.new && (
              <Box
                position="absolute"
                left="-30px"
                top="1.5"
                bg="brand.800"
                color="white"
                width="100px"
                transform={`rotate(${locale === "ar" ? "" : "-"}45deg)`}
              >
                <Text as={"div"}>
                  {formatMessage({
                    id: "new",
                  })}
                </Text>
              </Box>
            )}
          </Text>

          {/* Notifications */}
          {notifications && (
            <Tooltip
              label={`${formatMessage({
                id: "youHave",
              })} ${
                locale === "en" || notifications.count > 2
                  ? notifications.count
                  : ""
              } ${formatMessage({
                id:
                  notifications.count < 3 && locale === "ar"
                    ? `newSharedItems${notifications.count}`
                    : `newSharedItems3`,
              })}`}
              placement="top"
            >
              <Box
                position="absolute"
                right="-8px"
                top="-8px"
                bg="red.500"
                color="white"
                padding="5px"
                width="25px"
                height="25px"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {notifications.count}
              </Box>
            </Tooltip>
          )}

          <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
            <ModalOverlay />
            <ModalContent bg="transparent" boxShadow="none">
              <ModalCloseButton
                color="black"
                bgColor="white"
                borderRadius="50%"
              />
              <ModalBody p={0}>
                <Box p={20} bgColor="transparent" borderRadius="md">
                  <Box
                    as="iframe"
                    width="100%"
                    height="500px"
                    src={videoUrl}
                    allowFullScreen
                    allow="autoplay"
                    borderRadius="md"
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Link>
    </li>
  );
};

export default Category;
