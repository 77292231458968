import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Tag,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { titleSize, get } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import { FaVideo, FaPlay } from "react-icons/fa6";

interface SectionHeaderProps {
  slug: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ slug }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [videoUrl, setVideoUrl] = useState<string>("");

  const { locale } = useIntl();

  useEffect(() => {
    get(`/video-tutorial/${slug}`, locale).then((res: any) => {
      setVideoUrl(res.url || "");
    });
  }, [slug, locale]);

  return (
    <Box py={2}>
      <Box d="flex" alignItems="end" gap={2}>
        <Box
          width="4.5rem"
          height="4.5rem"
          borderRadius="50%"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
          position="relative"
          bgGradient="linear(to-br, #2451B7, #3B5FBD, #6F8AE3, #162d6a80)"
          overflow="hidden"
        >
          <Box
            as="button"
            aria-label="Play"
            backgroundColor="transparent"
            color="white"
            w="100%"
            h="100%"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.1)",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              boxShadow: "0 0 15px rgba(255, 255, 255, 0.3)",
            }}
            onClick={onOpen}
          >
            <FaPlay size="1.8rem" />
          </Box>
        </Box>
        <Box>
          <Tag
            backgroundColor="#EFEFEF"
            cursor="pointer"
            onClick={onOpen}
            _hover={{
              backgroundColor: "#E0E0E0",
              transform: "scale(1.05)",
              transition: "all 0.2s ease",
            }}
          >
            <Icon as={FaVideo} marginRight="2" color="#28282B" />
            <Text color="#28282B">
              <FormattedMessage id="video_tutorial" />
            </Text>
          </Tag>
          <Text
            as="h2"
            textStyle={"h2"}
            fontSize={titleSize}
            fontWeight="bold"
            color="#1f3f93"
            fontFamily={"Calibri (Body)"}
          >
            <FormattedMessage id={slug} />
          </Text>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalCloseButton color="black" bgColor="white" borderRadius="50%" />
          <ModalBody p={0}>
            <Box p={20} bgColor="transparent" borderRadius="md">
              <Box
                as="iframe"
                width="100%"
                height="500px"
                src={videoUrl}
                allowFullScreen
                allow="autoplay"
                borderRadius="md"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SectionHeader;
