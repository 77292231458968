import React, { useState, useEffect } from "react";
import {
  Text,
  useDisclosure,
  Button,
  Box,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import DescriptionModal from "./DescriptionModal";
import { get } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import CalibrationModal from "../Affirmation/Calibration/CalibrationModal";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import Category from "./Category";
import { FaVideo } from "react-icons/fa6";

type SectionProps = {
  categories: {
    route?: string;
    name: string;
  }[];
  slug: string;
  title: string;
  mainRoute: string;
  notifications: any;
};

const Section: React.FC<SectionProps> = ({
  categories,
  title,
  slug,
  mainRoute,
  notifications,
}) => {
  const { push } = useHistory();
  const { isOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const [content, setContent] = useState("");
  const { locale } = useIntl();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  useEffect(() => {
    get<any>("/description/" + slug.toLowerCase()).then((res) => {
      setContent(res?.content);
    });
  }, [locale, slug]);

  const { tutorialsEnabled } = useSelector((state) => state.tutorials);

  const {
    isOpen: isTutorialOpen,
    onOpen: onTutorialOpen,
    onClose: onTutorialClose,
  } = useDisclosure();

  const [tutorialUrl, setTutorialUrl] = useState("");

  return (
    <Box my={2} mx={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        backgroundColor="#1f3f93"
        borderRadius="12px"
        boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.35)"
        py={2}
        px={4}
        w={"15rem"}
      >
        <Button
          flex={1}
          bgColor="transparent"
          _focus={{}}
          _hover={{}}
          _active={{}}
          onClick={() => {
            push(mainRoute);
          }}
        >
          <Text
            textAlign="center"
            fontWeight="normal"
            style={{
              color: "white",
              fontSize: "18pt",
              fontFamily: "Calibri (Body)",
            }}
          >
            <FormattedMessage id={title} />
          </Text>
        </Button>
        {tutorialsEnabled && (
          <Icon
            key={`${title}-tutorial-icon`}
            as={FaVideo}
            color="white"
            aria-label="Play"
            fontSize="1.3em"
            backgroundColor="transparent"
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              // Call the API to get the tutorial URL based on title
              get<any>(`/video-tutorial/${title}`)
                .then((res) => {
                  setTutorialUrl(res?.url);
                  onTutorialOpen();
                })
                .catch((error) => {
                  console.error("Error fetching tutorial URL", error);
                });
            }}
          />
        )}
      </Box>

      <DescriptionModal
        finalRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        slug={slug}
        content={content}
      />
      <ul
        style={{
          listStyleType: "none",
          padding: "0",
          margin: "0",
        }}
      >
        {categories?.map((category) => {
          return (
            <Category
              key={category.route}
              category={category}
              notifications={
                notifications ? notifications[category.name] : null
              }
            />
          );
        })}
      </ul>
      <CalibrationModal
        children={
          <Button
            onClick={() => {
              calibrationFunc && calibrationFunc();
              setModalOpen(false);
            }}
            color={"white"}
            width={localStorage.getItem("locale") === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"20px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={
                  localStorage.getItem("locale") === "ar"
                    ? { marginRight: "1rem" }
                    : { marginLeft: "1px" }
                }
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
        }
        open={modalOpen}
        modalClose={() => setModalOpen(false)}
      />

      <Modal
        isOpen={isTutorialOpen}
        onClose={onTutorialClose}
        size="4xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalCloseButton color="black" bgColor="white" borderRadius="50%" />
          <ModalBody p={0}>
            <Box p={20} bgColor="transparent" borderRadius="md">
              <Box
                as="iframe"
                width="100%"
                height="500px"
                src={tutorialUrl}
                allowFullScreen
                allow="autoplay"
                borderRadius="md"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Section;
